import { WIX_FORUM as forumAppDefId } from '@wix/app-definition-ids';
import { getComponentByAppId } from '../utils/find-component';
import {
  BUTTON_BORDER_RADIUS,
  POST_BORDER_RADIUS,
  HEADER_HEIGHT,
  MAIN_PAGE_HEADER_HEIGHT,
} from '@wix/communities-forum-client-commons/dist/src/constants/wix-params';
import { wixPMobile } from '@wix/communities-forum-client-commons/dist/src/services/wix-params-utils';

export const setDefaultForumStyles = async (appToken, sdk) => {
  const appData = await sdk.tpa.app.getDataByAppDefId(appToken, forumAppDefId);
  const component = await getComponentByAppId(appToken, sdk, appData.applicationId);
  const compRef = await sdk.components.getById(appToken, { id: component.id });
  return sdk.document.tpa.setStyleParams(appToken, {
    compRef,
    styleParams: [
      {
        type: 'number',
        key: BUTTON_BORDER_RADIUS,
        param: {
          value: 8,
        },
      },
      {
        type: 'number',
        key: POST_BORDER_RADIUS,
        param: {
          value: 8,
        },
      },
      {
        type: 'number',
        key: wixPMobile(HEADER_HEIGHT),
        param: {
          value: 100,
        },
      },
      {
        type: 'number',
        key: wixPMobile(MAIN_PAGE_HEADER_HEIGHT),
        param: {
          value: 100,
        },
      },
    ],
  });
};
